$(function () {
  $("#component-record-form-modal .modal-content").on("ajax:success", "#remote_form_component_record", (event) => {
    [data, status, xhr] = event.detail
    if (xhr.getResponseHeader('Location') != null) {
      window.location.href = xhr.getResponseHeader('Location')
      return;
    }
  }).on("ajax:error", (event) => {
    [data, status, xhr] = event.detail
    renderFormErrors(event.target, "component_record", data);
  }).on("ajax:beforeSend", (event) => {

  })

  $(".modal-content").on("change", ".custom-file-input", event => {
    var fileName = event.target.files[0].name;
    $(event.target).parent().find(".custom-file-label").text(fileName)
  })
});
