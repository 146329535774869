$(function () {
  $(document).ready(function () {
    var componentTemplatesTable = $('#componentTemplates').DataTable({
      paging: false,
      searching: true,
      info: false
    });
    $('#componentTemplateSearch').keyup(function () {
      componentTemplatesTable.search($(this).val()).draw();
    })
  });
});
