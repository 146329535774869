let controller = null;
let signal = null;
fetchTableData = (dataUrl, params) => {
  if (controller) {
    controller.abort();
    controller = null;
    signal = null;
  }

  controller = new AbortController();
  signal = controller.signal;
  let urlParams = params.data;
  if (typeof (urlParams) !== 'string') {
    Object.keys(urlParams).map((key) => {
      if (!urlParams[key]) {
        delete urlParams[key];
      }
    })
    urlParams = $.param(urlParams);
  }
  fetch(`${ dataUrl }?${ urlParams }`, { signal })
    .then((res) => res.json())
    .then((res) => params.success(res))
    .catch((err) => {
      if (!(err.message && err.message === 'The user aborted a request.')) {
        console.log('data fetch error: ', err)
        params.error(err)
      }
    });
}

responseHandler = (res) => {
  const paginationIndexAdd = 1 + (res.current_page - 1) * res.per_page;
  res.rows.map((row, i) => {
    row.index = i + paginationIndexAdd;
  });
  return res;
};
