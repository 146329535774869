filterTable = (bTable) => {
  const searchUrlParams = new URLSearchParams(location.search);
  let params = {};
  filterParams = {};
  searchUrlParams.forEach((val, key) => {
    if (/\d+/.test(key)) {
      params[key] = val;
    } else if (/(date_|template)/.test(key)) {
      filterParams[key] = val;
    }
  });
  Object.keys(params).map((key, index) => {
    filterParams[`filters[${index}][property_id]`] = key
    filterParams[`filters[${index}][value]`] = params[key]
  })
  bTable.bootstrapTable("refresh", { query: filterParams });
};

updateSearchParams = (key, value=null) => {
  const searchParams = new URLSearchParams(location.search);
  if (!value && searchParams.has(key)) {
    searchParams.delete(key);
  } else if (value) {
    searchParams.set(key, value);
  }
  window.history.pushState(null, null, `${location.origin}${location.pathname}?${searchParams.toString()}`);
};
