$(function() {
  $("#task-form-modal .modal-content")
    .on("ajax:success", "#remote_form_task", (event) => {
      [data, status, xhr] = event.detail;
      if (xhr.getResponseHeader("Location") != null) {
        location.href = xhr.getResponseHeader("Location");
        return;
      }
    })
    .on("ajax:error", (event) => {
      [data, status, xhr] = event.detail;
      renderFormErrors(event.target, "task", data);
    })
    .on("ajax:beforeSend", (event) => {});

  let scheduleTable = $("#tcr-schedule");
  let scheduleTableDate;

  getQueryParams = (params) => {
    const searchUrlParams = new URLSearchParams(location.search);
    const paramsUrl = {};
    searchUrlParams.forEach((val, key) => {
      if (/\d+/.test(key)) {
        paramsUrl[key] = val;
      } else if (/(date_|template)/.test(key)) {
        params[key] = val;
      }
    });
    const urlParamsKeys = Object.keys(paramsUrl)
    if (urlParamsKeys.length) {
      urlParamsKeys.map((key, index) => {
        params[`filters[${index}][property_id]`] = key
        params[`filters[${index}][value]`] = paramsUrl[key]
      })
    }
    params.date = scheduleTableDate;
    return params;
  };

  const tasks_datepicker = $(".tasks-datepicker")
    .parent()
    .parent();

  flatpickr(".tasks-datepicker", {
    allowInput: true,
    locale: "pl",
    dateFormat: "l, d.m.Y",
    onChange: (selectedDates, dateStr, instance) => {
      scheduleTableDate = moment(selectedDates[0]).format("DD-MM-YYYY");
      scheduleTable.bootstrapTable("refresh");
    },
    defaultDate: new Date(),
    maxDate: new Date(),
    appendTo: tasks_datepicker[0],
  });
});
