$(function () {
  $("table").on("ajax:success", ".expand-slave", event => {
    $(event.currentTarget).addClass("expanded")
  })
  $("table").on("click", "a.expand-slave.expanded", event => {
    $(".expanded-slave").remove();
    $(event.currentTarget).removeClass("expanded");
    return false;
  })
})
