clearFormErrors = (target) => {
  const form = $(target)
  form.find('.is-invalid').removeClass('is-invalid')
  form.find('div.invalid-feedback').remove()
}

renderFormErrors = (target, model_name, errors) => {
  const form = $(target)
  const form_id = form.attr("id")
  clearFormErrors(target)
  for (const key in errors) {
    const field = key;
    const messages = errors[key];
    const input = null
    input = $(`#${form_id} [name*='${model_name}${field}'][type!='hidden']`);
    if(input.length == 0) {
      input = $(`#${form_id} [name*='${model_name}[${field}]'][type!='hidden']`);
    }

    input.addClass('is-invalid')
    // to fix input-group
    if (input.parent('.input-group').length > 0) {
      input = input.parent()
    }
    // rails by default return errors in array
    input.parent().append(`<div class="invalid-feedback"> ${messages[0]}</div>`)
  }
}
