saveCookie = (name, value) => {
  var cookie = [name, '=', JSON.stringify(value), '; domain=', window.location.hostname.toString(), '; path=/;'].join('');
  console.log(cookie)
  document.cookie = cookie;
}

readCookie = (name) => {
  var result = document.cookie.match(new RegExp(name + '=([^;]+)'));
  result && (result = JSON.parse(result[1]));
  return result;
}

deleteCookie = (name) => {
  document.cookie = [name, '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=', window.location.hostname.toString(), '; path=/;'].join('');
}
